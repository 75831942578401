<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-04-07 13:47:28
 * @LastEditTime : 2023-01-12 18:35:06
 * @LastEditors  : BigBigger
-->
<template>
  <div>
    <!-- 面试体验课 -->
    <div class="userInfo" v-if="trialClass">
      <div class="info-box">
        <div class="infoList">
          <span class="in-label in-label-req">真实姓名</span>
          <van-field
            clearable
            v-model="realName"
            placeholder="请输入真实姓名"
          />
        </div>
        <div class="infoList">
          <span class="in-label in-label-req">电话</span>
          <van-field clearable v-model="phone" placeholder="请输入手机号码" />
        </div>
        <div class="infoList">
          <span class="in-label in-label-req">考试类型</span>
          <van-dropdown-menu>
            <van-dropdown-item v-model="testType" :options="columns" />
          </van-dropdown-menu>
        </div>
        <div class="infoList">
          <span class="in-label in-label-req">退款支付宝账号</span>
          <van-field
            clearable
            v-model="alipayPhone"
            placeholder="课程结束后三个工作日退回"
          />
        </div>
      </div>
      <div class="saveInfo" @click="toSave">
        <p class="saveBtn">保存</p>
      </div>
    </div>
    <!-- 非面试体验课 -->
    <div class="userInfo" v-if="!trialClass">
      <div class="info-box">
        <div class="infoList">
          <span class="in-label in-label-req">真实姓名</span>
          <van-field
            clearable
            v-model="realName"
            placeholder="请输入真实姓名"
          />
        </div>
        <div class="infoList">
          <span class="in-label in-label-req">性别</span>
          <van-radio-group v-model="gender" direction="horizontal">
            <van-radio checked-color="#FF3F47" :name="1">男</van-radio>
            <van-radio checked-color="#FF3F47" :name="2">女</van-radio>
          </van-radio-group>
        </div>
        <div class="infoList">
          <span class="in-label in-label-req">电话</span>
          <van-field clearable v-model="phone" placeholder="请输入手机号码" />
        </div>
        <template v-if="interviewFlag === 3">
          <div class="infoList">
            <span class="in-label in-label-req">学校</span>
            <van-field
              clearable
              v-model="school"
              placeholder="请输入学校名称"
            />
          </div>
        </template>
        <template v-if="interviewFlag === 1">
          <div class="infoList">
            <span class="in-label in-label-req">身份证号</span>
            <van-field
              clearable
              v-model="idCard"
              placeholder="请输入身份证号码"
            />
          </div>
          <!-- <div class="infoList">
              <span class="in-label">地址</span>
              <van-field clearable v-model="address" placeholder="请输入所在地址" />
            </div> -->
        </template>
      </div>

      <div class="info-box" v-if="interviewFlag === 1">
        <div class="infoList">
          <span class="in-label in-label-req">笔试总分</span>
          <van-field
            clearable
            v-model="totalScore"
            placeholder="请输入笔试总分"
          />
        </div>
        <!-- <div class="write-rank"> -->
        <div class="infoList">
          <span class="in-label in-label-req">笔试排名</span>
          <van-field clearable v-model="ranking" placeholder="若未知，请填0" />
        </div>
        <!-- <div class="infoList" style="line-height: 1.2; padding: 0.2rem 0; color: red;font-size: 0.85em;">备注：出成绩前预报名同学，笔试分数和排名请默认0，出成绩后请在订单详情修改笔试分数和排名，出成绩后报名同学可正常填写自己真实笔试分数和排名。</div> -->
        <!-- <div class="infoList" v-else>
              <span class="in-label" style="min-width: 2.5rem;">本岗位最低进面分数</span>
              <van-field clearable v-model="lowestScore" placeholder="若未知，请填0" />
            </div> -->
        <!-- </div> -->
        <div class="infoList">
          <span class="in-label">准考证号</span>
          <van-field
            clearable
            v-model="admissionTicket"
            placeholder="请输入准考证号"
          />
        </div>
        <!-- style="width: 100%;line-height: 2;margin-top: 0.2rem;" -->
        <div
          class="infoList"
          v-if="isZJ && isShowLowestScore"
          style="flex-wrap: wrap"
        >
          <span class="in-label">成绩单截图凭证</span>
          <v-upload v-model="lowestScore" :maxSize="10485760" />
        </div>
        <!-- <div class="infoList" v-if="!isZJ">
            <span class="in-label">查分密码</span>
            <van-field clearable v-model="remark" placeholder="可不填" />
          </div> -->
      </div>
      <div class="info-box">
        <template v-if="interviewFlag === 1">
          <div class="infoList">
            <span class="in-label in-label-req">学校</span>
            <van-field
              clearable
              v-model="school"
              placeholder="请输入学校名称"
            />
          </div>
          <!-- <div class="infoList">
              <span class="in-label in-label-req">院系</span>
              <van-field clearable v-model="field02" placeholder="请输入院系" />
            </div> -->
          <div class="infoList">
            <span class="in-label in-label-req">学院</span>
            <van-field
              clearable
              v-model="profession"
              placeholder="请输入学院"
            />
          </div>
          <div class="infoList">
            <span class="in-label">新/老学员</span>
            <van-radio-group v-model="newStudent" direction="horizontal">
              <van-radio checked-color="#FF3F47" :name="1">新学员</van-radio>
              <van-radio checked-color="#FF3F47" :name="2">老学员</van-radio>
            </van-radio-group>
          </div>
        </template>
        <div class="infoList">
          <span class="in-label">应届/往届</span>
          <van-radio-group v-model="freshStudent" direction="horizontal">
            <van-radio checked-color="#FF3F47" :name="1">应届</van-radio>
            <van-radio checked-color="#FF3F47" :name="2">往届</van-radio>
          </van-radio-group>
        </div>
        <div class="infoList">
          <span class="in-label">备注</span>
          <van-field
            clearable
            v-model="remark"
            placeholder="如有特殊要求，请备注"
          />
        </div>
      </div>

      <div class="saveInfo" @click="toSave">
        <p class="saveBtn">保存</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getPersonalInfo, getUserInfo } from "@/api/api.js";
export default {
  name: "info",
  data() {
    return {
      interviewFlag: null,
      courseInfo: null,
      realName: "",
      gender: 1,
      phone: "",
      idCard: "",
      address: "",
      totalScore: "",
      ranking: "",
      lowestScore: "",
      newStudent: 1,
      admissionTicket: "",
      school: "",
      profession: "",
      freshStudent: 1,
      field02: "",
      remark: "",
      isZJ: process.env.VUE_APP_AREA === "zj" ? true : false,
      // 是否为面试体验课
      trialClass: true,
      // 是否是选调生面试
      isShowLowestScore: true,
      columns: [
        { text: "省考", value: "省考" },
        { text: "国考", value: "国考" },
        { text: "事业单位", value: "事业单位" },
        { text: "国考、省考都已参加", value: "国考、省考都已参加" },
      ],
      alipayPhone: "",
      testType: "省考",
    };
  },
  mounted() {
    this.getCourseInfo();
    window.addEventListener("resize", listener);
    // this.getPersonalInfo();
    if (this.$route.query.trialClass === "true") {
      this.trialClass = true;
    } else {
      this.trialClass = false;
    }
    if (this.$route.query.isShowLowestScore === "true") {
      this.isShowLowestScore = true;
    } else {
      this.isShowLowestScore = false;
    }
  },
  destroyed() {
    window.removeEventListener("resize", listener);
  },
  methods: {
    async getPersonalInfo() {
      let response = await getPersonalInfo();
      if (+response.returnCode !== 10001) {
        this.$toast(response.returnMsg);
        return false;
      }
      if (response.data) {
        response.data.totalScore = "";
        response.data.ranking = "";
        response.data.lowestScore = "";
        response.data.admissionTicket = "";
        this.setDefaultInfo(response.data);
      } else {
        getUserInfo().then((res) => {
          this.phone = res.data.phone;
        });
      }
    },
    check() {
      if (!this.realName) {
        this.$toast("请填写姓名");
        return false;
      }
      if (!this.phone) {
        this.$toast("请填写手机号");
        return false;
      }
      if (
        !/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(
          this.phone
        )
      ) {
        this.$toast("手机号格式错误");
        return false;
      }
      if (this.trialClass) {
        if (!this.alipayPhone) {
          this.$toast("请输入退款支付宝账号");
          return false;
        }
      } else {
        if (!this.school) {
          this.$toast("请填写学校");
          return false;
        }
      }
      // if (this.courseInfo.interviewFlag === 3) {
      // if(!this.school) {
      //   this.$toast('请填写学校')
      //   return false
      // }
      // if(!this.field02) {
      //   this.$toast('请填写院系')
      //   return false
      // }
      // }
      if (this.courseInfo.interviewFlag === 1) {
        if (!this.totalScore) {
          this.$toast("请填写笔试总分");
          return false;
        }
        if (!/^[+-]?(0|([1-9]\d*))(\.\d+)?$/.test(this.totalScore)) {
          this.$toast("笔试总分格式不正确");
          return false;
        }
        if (!this.ranking) {
          this.$toast("请填写笔试排名");
          return false;
        }
        if (!/^[0-9][0-9]?$/.test(this.ranking)) {
          this.$toast("请输入正确排名");
          return false;
        }
        // if (!this.admissionTicket) {
        //   this.$toast("请填写准考证号");
        //   return false;
        // }
        if (!this.profession) {
          this.$toast("请填写学院");
          return false;
        }
        // if (!this.lowestScore) {
        //   this.$toast("请上传成绩截图");
        //   return false;
        // }
        // if(!this.remark) {
        //   if(!this.isZJ){
        //     this.$toast('请填写查分密码')
        //     return false
        //   }
        // }
        if (!this.idCard) {
          this.$toast("请填写身份证号");
          return false;
        }
        // if(!/(\d)/.test(this.admissionTicket)) {
        //   this.$toast('准考证号格式不正确')
        //   return false
        // }
        if (
          !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
            this.idCard
          )
        ) {
          this.$toast("身份证号格式错误");
          return false;
        }
      }
      return true;
    },
    setDefaultInfo(defaultInfo) {
      this.realName = defaultInfo.realName;
      this.gender = defaultInfo.gender || 1;
      this.phone = defaultInfo.phone;
      this.remark = defaultInfo.remark;
      this.alipayPhone = defaultInfo.alipayPhone;
      this.testType = defaultInfo.testType || "省考";
      if (this.interviewFlag === 3) {
        this.school = defaultInfo.school;
      }
      if (this.interviewFlag === 1) {
        this.idCard = defaultInfo.idCard;
        this.address = defaultInfo.address;
        this.totalScore = defaultInfo.totalScore;
        this.ranking = defaultInfo.ranking;
        this.lowestScore = defaultInfo.lowestScore;
        this.newStudent = defaultInfo.newStudent || 1;
        this.admissionTicket = defaultInfo.admissionTicket;
        this.school = defaultInfo.school;
        this.profession = defaultInfo.profession;
      }
      this.freshStudent = defaultInfo.freshStudent || 1;
    },
    getCourseInfo() {
      let orderInfo = this.$store.state.orderInfo || null;
      if (orderInfo) {
        this.courseInfo = orderInfo.loCourseInfo;
        this.interviewFlag = this.courseInfo.interviewFlag;
        if (this.courseInfo.loUserInfo) {
          this.setDefaultInfo(this.courseInfo.loUserInfo);
        } else {
          this.getPersonalInfo();
        }
      } else {
        this.courseInfo = null;
        this.getPersonalInfo();
      }
    },
    toSave() {
      if (!this.check()) {
        return false;
      }
      if (this.courseInfo) {
        this.courseInfo.loUserInfo = {
          ...this.$store.state.orderInfo.loUserInfo,
          realName: this.realName,
          gender: this.gender,
          phone: this.phone,
          idCard: this.idCard,
          address: this.address,
          totalScore: this.totalScore,
          ranking: this.ranking,
          lowestScore: this.lowestScore,
          newStudent: this.newStudent,
          admissionTicket: this.admissionTicket,
          school: this.school,
          profession: this.profession,
          freshStudent: this.freshStudent,
          remark: this.remark,
          field02: this.field02,
          testType: this.testType,
          alipayPhone: this.alipayPhone,
        };
        if (this.trialClass) {
          this.courseInfo.loUserInfo.remark =
            this.testType + "+" + this.alipayPhone;
        }
        // window.localStorage.setItem('orderInfo', JSON.stringify(this.courseInfo))
        // window.history.go(-1)
        this.$store.dispatch("setOrderInfo", {
          ...this.$store.state.orderInfo,
          loUserInfo: this.courseInfo.loUserInfo,
        });
        this.$router.go(-1);
      } else {
        // TODO 跳转到首页
      }
    },
  },
};

function listener() {
  setTimeout(() => {
    document.activeElement.scrollIntoView({ block: "center" });
  }, 20);
}
</script>
<style lang="less" scoped>
.userInfo {
  background: #f3f3f3;
  padding-bottom: 1.4rem;
}
.info-box {
  //   width:94%;
  padding: 0 3%;
  background: #fff;
  // margin-bottom: 0.25rem;
}
.userInfo .infoList {
  display: flex;
  font-size: 14px;
  color: #5b6b73;
  line-height: 1rem;
  text-align: left;
  // margin:0.15rem 0;
  border-bottom: 1px #f2f2f5 solid;
  display: flex;
  align-items: center;
}
.userInfo .infoList span {
  width: 30%;
}
.userInfo .infoList input[type="text"] {
  color: #333;
}
.userInfo .infoList .info-right input[type="radio"] {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.05rem;
}
.userInfo .infoList .info-right input[type="radio"]:before {
  width: 0.22rem;
  height: 0.22rem;
}
input[type="radio"]:checked:after {
  top: 0.08rem;
  left: 0.05rem;
}
.userInfo .infoList .info-right label {
  margin-right: 0.3rem;
}
.write-rank {
  border-bottom: 1px #f2f2f5 solid;
}
.write-rank .infoList {
  border: none;
}
.write-rank p {
  color: #5b6b73;
  font-size: 12px;
  padding-bottom: 0.2rem;
}
.saveInfo {
  width: 100%;
  height: 1.4rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  /* padding:0.3rem 0; */
}
.saveInfo .saveBtn {
  width: 94%;
  height: 0.8rem;
  line-height: 0.8rem;
  background: #ff3f47;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  margin: 0.3rem 3%;
}
.info-box {
  font-size: 14px;
}
.in-label {
  padding-left: 0.1rem;
}
.in-label-req::before {
  display: inline-block;
  content: "*";
  color: #ff3f47;
}
.van-dropdown-menu {
  width: 100%;
}
::v-deep .van-dropdown-menu__bar {
  box-shadow: none !important;
}
::v-deep .van-dropdown-menu__item {
  justify-content: start !important;
  -webkit-justify-content: start;
}
</style>
